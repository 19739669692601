import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import "./index.css";
import App from "./App";
import i18n from './i18n';
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import "bootstrap/dist/css/bootstrap.min.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
