import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Card, CardGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { cardData } from "./questions";
import TotalScoreContext from "./TotalScoreContext";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import ReactSwitch from "react-switch";

//import { Question } from '../Server/Server';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const validator = require("validator");

function Forrm() {
  const [answers, setAnswers] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    Array(cardData.length).fill(null)
  );
  const [lastAnsweredQuestion, setLastAnsweredQuestion] = useState(-1);
  //const [score, setScore] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const [totalScore, setTotalScore] = useState(1);
  //const [selectedOption, setSelectedOption] = useState(Array(cardData.length).fill(null));
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const { totalScore1, setTotalScore1 } = useContext(TotalScoreContext);
  const [updatedTotalScore1, setUpdatedTotalScore1] = useState(null);

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  //const shouldEnableSubmit = answeredQuestions.length === cardData.length && formSubmitted;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    console.log(`Changing language to ${lng}`);
    i18n.changeLanguage(lng);
  };

  const handleAnswer = (questionIndex) => {
    setAnsweredQuestions([...answeredQuestions, questionIndex]);
    setActiveQuestion(activeQuestion + 1);

    //     //new
    //     const selectedOption1 = selectedOption[questionIndex];
    //      const score = cardData[questionIndex].options.find((option) => option.label === selectedOption1)?.weightage || 0;
    //    // const score = 2
    //     setTotalScore(totalScore + score);

    if (activeQuestion === cardData.length - 1) {
      setSubmitButtonClicked(true);
      // navigate.push(`/result?score=${updatedTotalScore}`)
    }
    setIsSubmitted(true);
  };

  useEffect(() => {
    // Load answers from local storage on page load
    const savedAnswers = JSON.parse(localStorage.getItem("answers"));
    if (savedAnswers) {
      setAnswers(savedAnswers);
    }
  }, []);

  const isQuestionAnswered = (questionIndex) => {
    return answeredQuestions.includes(questionIndex);
  };

  const saveAnswersToLocalStorage = (answers) => {
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  const handleButtonClick = async () => {
    // window.location.href = `/result?score=${updatedTotalScore1}`;
    const isValidEmail = validator.isEmail(email);
    const isValidPhoneNumber = validator.isMobilePhone(phoneNumber);

    if (!isValidEmail) {
      console.log("Invalid email address:", email);
    }
    setIsValidEmail(true);

    if (!isValidPhoneNumber) {
      console.log("Invalid phone number:", phoneNumber);
    }
    setIsValidPhoneNumber(true);

    const testType = 4;

    if (isAllQuestionsAnswered() && isValidEmail && isValidPhoneNumber) {
      try {
        const postData = {
          score: updatedTotalScore1,
          role: testType,
          name: name,
          email: email,
          mobile: phoneNumber,
          // ... other necessary data
        };
        const response = await axios.post(
          "https://api.positivemindcare.com/api/v1/user/",
          postData
        );

        console.log("API response:", response.data);

        // No need for navigate here
      } catch (error) {
        console.error("Error posting score to API:", error);
      }
      console.log("handleButtonClick called");
      console.log("isSubmitted:", isSubmitted);
      console.log("isValidEmail:", isValidEmail);
      console.log("isValidPhoneNumber:", isValidPhoneNumber);
      console.log("updatedTotalScore1:", updatedTotalScore1);
      navigate(`/result?score=${encodeURIComponent(updatedTotalScore1)}`);
    }
  };

  const handleOptionChange = (event, questionIndex) => {
    const optionValue = event.target.value;
    const updatedSelectedOptions = [...selectedOption];
    updatedSelectedOptions[questionIndex] = optionValue;
    setSelectedOption(updatedSelectedOptions);

    if (!isQuestionAnswered(questionIndex + 1)) {
      setAnsweredQuestions([...answeredQuestions, questionIndex]);
      setActiveQuestion(activeQuestion + 1);
    }

    const updatedTotalScore = updatedSelectedOptions.reduce(
      (score, option, index) => {
        const selectedOption = cardData[index].options.find(
          (o) => o.label === option
        );
        return score + (selectedOption ? selectedOption.weightage : 0);
      },
      0
    );

    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = optionValue;
    setAnswers(updatedAnswers);
    saveAnswersToLocalStorage(updatedAnswers);

    setTotalScore(updatedTotalScore);

    //console.log(updatedTotalScore)

    //const updatedTotalScore1 = updatedTotalScore;

    setUpdatedTotalScore1(updatedTotalScore);
    //console.log(updatedTotalScore1)

    console.log("Updated Total Score:", updatedTotalScore);

    if (answeredQuestions.length === cardData.length) {
      //   console.log(updatedTotalScore1)

      handleButtonClick();

      //setIsSubmitted(true);
      //handleButtonClick();
      //navigate(`/result?score=${updatedTotalScore1}`);
      console.log("answeredQuestions:", answeredQuestions);
      console.log("cardData.length:", cardData.length);
      console.log("updatedTotalScore1:", updatedTotalScore1);
    } else {
      console.log("Please answer all questions");
    }
  };

  const isAllQuestionsAnswered = () => {
    // return answeredQuestions.length === cardData.length;
    // return selectedOption.every((option) => option !== null) && isSubmitted;
    return selectedOption.every((option) => option !== null);
  };
  const handleAnswerChange = (questionId, answerIndex) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionId] = answerIndex;
    setAnswers(updatedAnswers);

    if (questionId === lastAnsweredQuestion + 1) {
      setLastAnsweredQuestion(questionId);
    }

    const isAllQuestionsAnswered = answeredQuestions.length === cardData.length;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    const isValidEmail = validator.isEmail(email);
    const isValidPhoneNumber = validator.isMobilePhone(phoneNumber);

    if (isAllQuestionsAnswered() && isValidEmail && isValidPhoneNumber) {
      try {
        const testType = 4;
        const postData = {
          score: updatedTotalScore1,
          role: testType,
          name: name,
          email: email,
          mobile: phoneNumber,
          // ... other necessary data
        };

        const response = await axios.post(
          "https://api.positivemindcare.com/api/v1/user/",
          postData
        );

        console.log("API response:", response.data);

        navigate(`/result?score=${encodeURIComponent(updatedTotalScore1)}`);
      } catch (error) {
        console.error("Error posting score to API:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("No response received from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error:", error.message);
        }

        if (!isValidEmail) {
          console.log("Invalid email address:", email);
        }

        if (!isValidPhoneNumber) {
          console.log("Invalid phone number:", phoneNumber);
        }
      }
    }
  };

  return (
    <>
      <Container className="context1 ">
        <div className="mb-5">
          <ReactSwitch
            checked={selectedLanguage === "en"}
            onChange={(nextChecked) => {
              const newLanguage = nextChecked ? "en" : "hi";
              setSelectedLanguage(newLanguage);
              // Call the function to change the language based on newLanguage
              handleLanguageChange(newLanguage);
            }}
            handleDiameter={28}
            offColor="#d9d9d9"
            onColor="#d9d9d9"
            offHandleColor="#4d4d4d"
            onHandleColor="#4d4d4d"
            height={40}
            width={100}
            borderRadius={40}
            activeBoxShadow="0px 0px 1px 2px #fffc35"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "black",
                  paddingRight: 5,
                }}
              >
                Hindi
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "black",
                  paddingLeft: 5,
                }}
              >
                English
              </div>
            }
            className="react-switch"
            id="small-radius-switch"
          />
        </div>
        <Form onSubmit={handleSubmit}>
          <div>
            <Row>
              {cardData.map((questionObj, questionIndex) => (
                <Col key={questionIndex} md={6}>
                  <Card
                    key={questionIndex}
                    className={
                      questionIndex < activeQuestion ? "muted-card" : ""
                    }
                    id="cs"
                  >
                    <Card.Body
                      style={{ opacity: isQuestionAnswered ? 1 : 0.5 }}
                    >
                      <Card.Title>
                        {t(questionObj.title, selectedLanguage)}
                      </Card.Title>

                      {questionObj.options.map((option, optionIndex) => (
                        <Form.Check
                          key={optionIndex}
                          type="radio"
                          label={t(option.label, selectedLanguage)}
                          value={option.label}
                          id={`question-${questionIndex}-option-${option.id}`}
                          disabled={
                            !isQuestionAnswered(questionIndex - 1) &&
                            questionIndex !== 0
                          }
                          checked={
                            selectedOption[questionIndex] === option.label
                          }
                          onChange={(event) =>
                            handleOptionChange(event, questionIndex)
                          }
                        />
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <div className="p-4 flex justify-center">
            <Popup
              trigger={
                <Button
                  className="button relative z-10"
                  disabled={
                    !isAllQuestionsAnswered() ||
                    (isSubmitted && activeQuestion === cardData.length)
                  }
                  onClick={() => {
                    handleAnswer(activeQuestion);
                    // handleButtonClick();
                  }}
                  variant="outline-primary"
                  type="submit"
                  style={{ fontSize: "18px", bordercolor: "rgb(155,43,120)" }}
                >
                  Submit
                </Button>
              }
              modal
            >
              <Container className="">
                <div className="flex justify-center">
                  <img
                    src="/Assets/ezgif.com-webp-to-png.png"
                    alt="no-im"
                    className="h-24 p-1.5 m-4"
                  />
                </div>

                <div className="m-1">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fs-" id="name">
                      Name
                    </Form.Label>

                    <Form.Control
                      type="name"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      //isInvalid={!isValidEmail}
                      size="sm"
                      className="border"
                    />

                    {/* {!isValidEmail && (
                      <Form.Control.Feedback type="invalid">
                        Invalid email address.
                      </Form.Control.Feedback>
                    )} */}
                  </Form.Group>
                </div>

                <div className="m-1">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fs-" id="name">
                      Email Address
                    </Form.Label>

                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={isValidEmail}
                      size="sm"
                      className="border"
                    />

                    {!isValidEmail && (
                      <Form.Control.Feedback type="invalid">
                        Invalid email address.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>

                <div className="m-1">
                  <Form.Group className="mb-3" controlId="phone-number-input">
                    <Form.Label className="fs-6" id="name1">
                      Mobile Number
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter mobile number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      isInvalid={isValidPhoneNumber}
                      size="sm"
                    />
                    {!isValidPhoneNumber && (
                      <Form.Control.Feedback type="invalid">
                        Invalid phone number.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="flex justify-center p-1 m-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleButtonClick}
                  >
                    {/* <Link to={updatedTotalScore1 !== undefined ? `/result?score=${encodeURIComponent(updatedTotalScore1)}` : "#"}>
                    Submit
                    </Link> */}
                    Submit
                  </Button>
                </div>
              </Container>
            </Popup>
          </div>
        </Form>
      </Container>
    </>
  );
}

export default Forrm;
