import React, { useContext, useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";

import { useLocation, useNavigate } from "react-router-dom";
import TotalScoreContext from "./TotalScoreContext";
import { useTotalScore } from "./TotalScoreContext";
import ResultInfo from "./ResultInfo";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import ContactUs from "./ContactUs";
import Note from "./Note";

function Resultpage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const updatedTotalScore = parseInt(queryParams.get("score"));
  console.log("Total Score:", updatedTotalScore);

  const { setTotalScore1 } = useContext(TotalScoreContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentColor, setCurrentColor] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isSubmitted && isNaN(updatedTotalScore)) {
      setTotalScore1(updatedTotalScore);
      navigate(`/result?score=${updatedTotalScore}`);
    }
  }, [isSubmitted, navigate, setTotalScore1, updatedTotalScore]);

  const totalScoreValue = isNaN(updatedTotalScore) ? 0 : updatedTotalScore;

  useEffect(() => {
    if (updatedTotalScore !== null) {
      const index = Math.floor(updatedTotalScore / 7.5);
      const colorNames = ["Green", "Orange", "Red"];
      setCurrentColor(colorNames[index]);
    }
  }, [updatedTotalScore]);

  const getAnxietyLevel = () => {
    if (updatedTotalScore >= 0 && updatedTotalScore <= 7) {
      return "Low";
    } else if (updatedTotalScore > 7 && updatedTotalScore <= 14) {
      return "Moderate";
    } else if (updatedTotalScore > 14 && updatedTotalScore < 40) {
      return "Severe";
    } else {
      return "Unknown";
    }
  };

  const low = `- These scores indicate low risks of alcohol related disorder.<br/> <br>
  - Connect with our psychologists at Positive Mind Care. <br/> <br>
  - Practice Mindfulness Sessions. <br/> <br> 
  
  
  `;
  const moderate = `- These scores indicate moderate risk of developing Alcohol Dependence.<br/> <br>
  - We recommend to Seek psychiatric Consultation at Positive Mind Care<br/> <br>
  - Or Recommendation for psychological Counselling.<br/> <br> 
  - Try in Mindfulness sessions.
  
  
  `;

  const severe = `- The answers Indicate that you're at a severe risk of having alcohol dependence.<br/> <br> 
  - After this we recommend you to Seek psychiatric consultation immediately.  <br/> <br>
  - Psychological counselling is a must recommend for you.<br/> <br>
  - Mindfulness sessions can help a lot.<br/> <br>
  - Do not panic, Positive Mind Care is here to support you.

  
  `;

  const getAnxietyLevelText = () => {
    if (updatedTotalScore >= 0 && updatedTotalScore <= 7) {
      return (
        <div>
          <p
            className="md:max-w-md"
            dangerouslySetInnerHTML={{ __html: low }}
          ></p>
        </div>
      );
    } else if (updatedTotalScore > 7 && updatedTotalScore <= 14) {
      return (
        <div>
          <p
            className="md:max-w-md"
            dangerouslySetInnerHTML={{ __html: moderate }}
          ></p>
        </div>
      );
    } else if (updatedTotalScore > 14 && updatedTotalScore < 40) {
      return (
        <div>
          <p
            className="md:max-w-md"
            dangerouslySetInnerHTML={{ __html: severe }}
          ></p>
        </div>
      );
    } else {
      return "Unknown";
    }
  };

  const anxietyLevel = getAnxietyLevel();
  const anxietyLevelText = getAnxietyLevelText();
  return (
    <div>
      <div className="flex  bg-[#e0ffff] ">
        <article class="container flex flex-col md:flex-col lg:flex-row  bg-[#e0ffff] mb-10 ">
          <Card className="flex flex-grow-1 md:mr-4  md:mb-0 shadow-2xl shadow-teal-500 mb-3 ">
            <Card.Body>
              <h4 className="flex justify-center m-4 pt-2">
                ALCOHOL ADDICTION TEST RESULT
              </h4>
              <div className="flex justify-center pt-1">
                <ReactSpeedometer
                  maxValue={40}
                  value={totalScoreValue}
                  height={200}
                  needleHeightRatio={0.7}
                  needleColor="red"
                  // startColor="green"
                  segments={4}
                  // endColor="red"
                  customSegmentStops={[0, 7, 14, 40]}
                  segmentColors={["green", "orange", "tomato"]}
                  currentValueText="Alcohol Addiction Level"
                  customSegmentLabels={[
                    {
                      text: "LOW",
                      position: "OUTSIDE",
                    },
                    {
                      text: "MODERATE",
                      position: "OUTSIDE",
                      //fontSize: "19px",
                    },
                    {
                      text: "SEVERE",
                      position: "OUTSIDE",
                    },
                  ]}
                  className="flex justify-center "
                />
              </div>
              <div className="italic text-[#2755a3] cong">
                <p className="x fw-bold md:max-w-md p-3 m-3 text-md">
                  Congratulations on completing the Alcohol Addiction Level
                  Test!! It was really Quick and Interesting !!!
                </p>
              </div>
              <div className="relative flex flex-col items-baseline ">
                <ResultInfo
                  className="titles"
                  updatedTotalScore={updatedTotalScore}
                  currentColor={currentColor}
                />

                <p className="flex fw-bold" id="level">
                  Your Alcohol Addiction Level Is {anxietyLevel}
                </p>

                <p className="flex p-3 m-3" id="details">
                  {anxietyLevelText}
                </p>

                <div className="container flex  align-center justify-evenly flex-col md:flex-row ">
                  <Button
                    href="https://www.positivemindcare.com/"
                    className=" text-sm  font-medium flex-grow-1 md:w-1/4 m-4 rounded-full but"
                  >
                    Know More
                  </Button>

                  <Button
                    variant="primary"
                    href="https://www.positivemindcare.com/contact-us/"
                    className=" text-sm font-medium flex-grow-1  md:w-1/4 m-4 mb-4 but"
                  >
                    Contact Us
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className="conatiner flex flex-grow-1 items-center shadow-2xl shadow-teal-500 mb-3">
            <Card.Body className="m-4 p-4">
              <div className="flex justify-center container">
                <h4 className="">OUR PACKAGES</h4>
              </div>
             
              <div className="relative container flex flex-col md:flex-row justify-center flex-grow-1 ">
                {anxietyLevel === "Low" && (
                  <>
                    <Card
                      style={{ width: "20rem" }}
                      className="flex m-2 p-2 flex-grow-1 md:mr-4 mb-4 md:mb-0 "
                    >
                      <Card.Img
                        variant="top"
                        className="object-cover h-48 w-24"
                        src="/Assets/Woman characters having business conversation or meeting.jpg"
                      />
                      <Card.Body>
                        <Card.Title className="flex justify-center tex">
                          Psychological Counselling
                        </Card.Title>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (45 minutes to 1-hour session)
                        </Card.Text>
                        <Card.Text className="text-[#d59438] text-lg fw-bold m-0 flex justify-center tex">
                          Upto Rs. 400
                        </Card.Text>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (Per session charge)
                        </Card.Text>

                        <Card.Text>
                          <ListGroup className="flex text-xs" as="ul" id="">
                            <ListGroup.Item as="li">
                              Single Session Charge - Rs 1,200
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Monthly Plan - 04 Sessions - Rs 4,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Quarterly Plan - 12 Sessions - Rs. 10,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Half Yearly Plan - 25 Sessions - Rs. 15,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Yearly Plan - 50 Sessions - Rs. 20,000
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Text>
                        <div className="flex justify-center">
                          <Button
                            href="https://formbuilder.ccavenue.com/live/au-small-finance-bank/positive-mind-care"
                            variant="primary"
                            className="tex but"
                          >
                            Book Now
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card
                      style={{ width: "20rem" }}
                      className="flex m-2 p-3 flex-grow-1 md:mr-2 mb-4 md:mb-0 "
                    >
                      <Card.Img
                        variant="top"
                        className="object-cover h-48 w-24"
                        src="/Assets/5184243.jpg"
                      />
                      <Card.Body>
                        <Card.Title className="flex justify-center tex">
                          Mindfulness Package
                        </Card.Title>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (45 minutes to 1-hour session)
                        </Card.Text>
                        <Card.Text className="text-[#d59438] text-lg fw-bold m-0 flex justify-center tex">
                          Rs. 300
                        </Card.Text>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (Per session charge)
                        </Card.Text>

                        <Card.Text>
                          <ListGroup className="flex text-xs" as="ul" id="">
                            <ListGroup.Item as="li">
                              Monthly Plan - 04 Sessions - Rs 2,500
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Quarterly Plan - 12 Sessions - Rs. 5,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Half Yearly Plan - 25 Sessions - Rs. 10,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Yearly Plan - 50 Sessions - Rs. 15,000
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Text>
                        <div className="flex justify-center">
                          <Button
                            href="https://formbuilder.ccavenue.com/live/au-small-finance-bank/positive-mind-care"
                            variant="primary"
                            className="tex but"
                          >
                            Book Now
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                )}
                {anxietyLevel === "Moderate" && (
                  <>
                    <Card
                      style={{ width: "20rem" }}
                      className="flex m-4 p-3 flex-grow-1 md:mr-4 mb-4 md:mb-0 "
                    >
                      <Card.Img
                        variant="top"
                        className="object-cover h-48 w-24"
                        src="/Assets/doctors.jpg"
                      />
                      <Card.Body>
                        <Card.Title className="flex justify-center tex">
                          Psychiatrist Consultation
                        </Card.Title>
                        {/* <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                        (45 minutes to 1-hour session)
                      </Card.Text> */}
                        {/* <Card.Text className="text-[#d59438] text-lg fw-bold m-0 flex justify-center tex">
                        Rs. 300
                      </Card.Text> */}
                        {/* <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                        (Per session charge)
                      </Card.Text> */}

                        <Card.Text>
                          <ListGroup className="flex text-xs" as="ul" id="">
                            <ListGroup.Item as="li">
                              In Clinic - Rs 1000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Online - Rs 700
                            </ListGroup.Item>
                            {/* <ListGroup.Item as="li">
                            Half Yearly Plan - 25 Sessions - Rs. 10,000
                          </ListGroup.Item>
                          <ListGroup.Item as="li">
                            Yearly Plan - 50 Sessions - Rs. 15,000
                          </ListGroup.Item> */}
                            <div className="conatiner m-4 ">
                              <p className="flex justify-center text-[11px] ">
                                Book Now, And Avail offer upto 20% off
                              </p>
                            </div>
                          </ListGroup>
                        </Card.Text>
                        <div className="flex justify-center">
                          <Button
                            href="https://www.positivemindcare.com/appointments/"
                            variant="primary"
                            className="tex but"
                          >
                            Book Now
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card
                      style={{ width: "20rem" }}
                      className="flex m-4 p-3 flex-grow-1 md:mr-4 mb-4 md:mb-0 car"
                    >
                      <Card.Img
                        variant="top"
                        className="object-cover h-48 w-24"
                        src="/Assets/Woman characters having business conversation or meeting.jpg"
                      />
                      <Card.Body>
                        <Card.Title className="flex justify-center tex">
                          Psychological Counselling
                        </Card.Title>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (45 minutes to 1-hour session)
                        </Card.Text>
                        <Card.Text className="text-[#d59438] text-lg fw-bold m-0 flex justify-center tex">
                          Upto Rs. 400
                        </Card.Text>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (Per session charge)
                        </Card.Text>

                        <Card.Text>
                          <ListGroup className="flex text-xs" as="ul" id="">
                            <ListGroup.Item as="li">
                              Single Session Charge - Rs 1,200
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Monthly Plan - 04 Sessions - Rs 4,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Quarterly Plan - 12 Sessions - Rs. 10,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Half Yearly Plan - 25 Sessions - Rs. 15,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Yearly Plan - 50 Sessions - Rs. 20,000
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Text>
                        <div className="flex justify-center">
                          <Button
                            href="https://formbuilder.ccavenue.com/live/au-small-finance-bank/positive-mind-care"
                            variant="primary"
                            className="tex but"
                          >
                            Book Now
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                )}

                {anxietyLevel === "Severe" && (
                  <>
                    <Card
                      style={{ width: "20rem" }}
                      className="flex m-1 p-3 flex-grow-1 md:mr-4 mb-4 md:mb-0 car  "
                    >
                      <Card.Img
                        variant="top"
                        className="object-cover h-48 w-24"
                        src="/Assets/doctors.jpg"
                      />
                      <Card.Body>
                        <Card.Title className="flex justify-center tex">
                          Psychiatrist Consultation
                        </Card.Title>
                        {/* <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                        (45 minutes to 1-hour session)
                      </Card.Text> */}
                        {/* <Card.Text className="text-[#d59438] text-lg fw-bold m-0 flex justify-center tex">
                        Rs. 300
                      </Card.Text> */}
                        {/* <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                        (Per session charge)
                      </Card.Text> */}

                        <Card.Text>
                          <ListGroup className="flex text-xs" as="ul" id="">
                            <ListGroup.Item as="li">
                              In Clinic - Rs 1000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Online - Rs 700
                            </ListGroup.Item>
                            {/* <ListGroup.Item as="li">
                            Half Yearly Plan - 25 Sessions - Rs. 10,000
                          </ListGroup.Item>
                          <ListGroup.Item as="li">
                            Yearly Plan - 50 Sessions - Rs. 15,000
                          </ListGroup.Item> */}
                            <div className="conatiner m-4 ">
                              <p className="flex justify-center text-[11px] ">
                                Book Now, And Avail offer upto 20% off
                              </p>
                            </div>
                          </ListGroup>
                        </Card.Text>
                        <div className="flex justify-center">
                          <Button
                            href="https://www.positivemindcare.com/appointments/"
                            variant="primary"
                            className="tex but"
                          >
                            Book Now
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card
                      style={{ width: "20rem" }}
                      className="flex m-1 p-3 flex-grow-1 md:mr-4 mb-4 md:mb-0 "
                    >
                      <Card.Img
                        variant="top"
                        className="object-cover h-48 w-24"
                        src="/Assets/Woman characters having business conversation or meeting.jpg"
                      />
                      <Card.Body>
                        <Card.Title className="flex justify-center tex">
                          Psychological Counselling
                        </Card.Title>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (45 minutes to 1-hour session)
                        </Card.Text>
                        <Card.Text className="text-[#d59438] text-lg fw-bold m-0 flex justify-center tex">
                          Upto Rs. 400
                        </Card.Text>
                        <Card.Text className="text-[#6b7280] text-sm flex justify-center tex">
                          (Per session charge)
                        </Card.Text>

                        <Card.Text>
                          <ListGroup className="flex text-xs" as="ul" id="">
                            <ListGroup.Item as="li">
                              Single Session Charge Rs 1,200
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Monthly Plan - 04 Sessions - Rs 4,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Quarterly Plan - 12 Sessions - Rs. 10,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Half Yearly Plan - 25 Sessions - Rs. 15,000
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                              Yearly Plan - 50 Sessions - Rs. 20,000
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Text>
                        <div className="flex justify-center">
                          <Button
                            href="https://formbuilder.ccavenue.com/live/au-small-finance-bank/positive-mind-care"
                            variant="primary"
                            className="tex but"
                          >
                            Book Now
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </div>
             
              {updatedTotalScore >= 8 ? (
                <div className="container mt-32 p-3 ">
                  <div className="tex container flex flex-nowrap justify-center align-center">
                    <p className="max-w-lg text-sm">
                      Note: We also offer Mindfulness package, which could be of
                      great help in this. Kindly Connect with us for more
                      information about this.
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </Card.Body>
          </Card>

          {/* <ContactUs/> */}

          {console.log({ updatedTotalScore })}
          {console.log({ currentColor })}
        </article>
      </div>
      <div className="tek3">
        <Note />
      </div>
    </div>
  );
}

export default Resultpage;
