
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language
    debug: true, // Enable logging for debugging
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    resources: {
      en: {
        translation: {
          
            "1. How often do you have a drink containing alcohol ?": "1. How often do you have a drink containing alcohol ?",
            "a) Never": "a) Never",
            "b) Monthly or less": "b) Monthly or less",
            "c) 2-4 times a month": "c) 2-4 times a month",
            "d) 2-3 times a week": "d) 2-3 times a week",
            "e) 4 or more times a week": "e) 4 or more times a week",
            "2. How many drinks containing alcohol do you have on a typical day when you are drinking ?": "2. How many drinks containing alcohol do you have on a typical day when you are drinking ?",
            "a) 1 or 2": "a) 1 or 2",
            "b) 3 or 4": "b) 3 or 4",
            "c) 5 or 6": "c) 5 or 6",
            "d) 7 or 9": "d) 7 or 9",
            "e) 10 or more": "e) 10 or more",
            "3. How often do you have six or more drinks on one occasion ?": "3. How often do you have six or more drinks on one occasion ?",
            "a) Never": "a) Never",
            "b) Less than monthly": "b) Less than monthly",
            "c) Monthly": "c) Monthly",
            "d) Weekly": "d) Weekly",
            "e) Daily or almost Daily": "e) Daily or almost Daily",
            "4. How often during the last year have you found that you were not able to stop drinking once you had started ?": "4. How often during the last year have you found that you were not able to stop drinking once you had started ?",
            "a) Never": "a) Never",
            "b) Less than monthly": "b) Less than monthly",
            "c) Monthly": "c) Monthly",
            "d) Weekly": "d) Weekly",
            "e) Daily or Almost Daily": "e) Daily or Almost Daily",
            "5. How often during the last year you failed to do so what was normally expected of you because of drinking ?": "5. How often during the last year you failed to do so what was normally expected of you because of drinking ?",
            "a) Never": "a) Never",
            "b) Less than monthly": "b) Less than monthly",
            "c) Monthly": "c) Monthly",
            "d) Weekly": "d) Weekly",
            "e) Daily or Almost Daily": "e) Daily or Almost Daily",
            "6. How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session ?": "6. How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session ?",
            "a) Never": "a) Never",
            "b) Less than monthly": "b) Less than monthly",
            "c) Monthly": "c) Monthly",
            "d) Weekly": "d) Weekly",
            "e) Daily or Almost Daily": "e) Daily or Almost Daily",
            "7. How often during the last year have you had a feeling of guilt or remorse after drinking ?": "7. How often during the last year have you had a feeling of guilt or remorse after drinking ?",
            "a) Never": "a) Never",
            "b) Less than monthly": "b) Less than monthly",
            "c) Monthly": "c) Monthly",
            "d) Weekly": "d) Weekly",
            "e) Daily or Almost Daily": "e) Daily or Almost Daily",
            "8. How often during the last year have you been unable to remember what happened the night before because of your drinking ?": "8. How often during the last year have you been unable to remember what happened the night before because of your drinking ?",
            "a) Never": "a) Never",
            "b) Less than monthly": "b) Less than monthly",
            "c) Monthly": "c) Monthly",
            "d) Weekly": "d) Weekly",
            "e) Daily or Almost Daily": "e) Daily or Almost Daily",
            "9. Have you or someone else been injured because of your drinking ?": "9. Have you or someone else been injured because of your drinking ?",
            "a) No": "a) No",
            "b) Yes, but not in the last year": "b) Yes, but not in the last year",
            "c) Yes, during the last year": "c) Yes, during the last year",
            "10. Has a relative, friend, doctor, or other healthcare worker been concerned about your drinking or suggested that you cut down?": "10. Has a relative, friend, doctor, or other healthcare worker been concerned about your drinking or suggested that you cut down?",
            "a) No": "a) No",
            "b) Yes, but not in the last year": "b) Yes, but not in the last year",
            "c) Yes, during the last year": "c) Yes, during the last year"
          }
          
       
      },
      hi: {
        translation: {
          
          
            "1. How often do you have a drink containing alcohol ?": "1. आप कितनी बार अल्कोहल युक्त पेय पीते हैं?",
            "a) Never": "a) कभी नहीं",
            "b) Monthly or less": "b) मासिक या उससे कम",
            "c) 2-4 times a month": "c) महीने में 2-4 बार",
            "d) 2-3 times a week": "d) हफ्ते में 2-3 बार",
            "e) 4 or more times a week": "e) हफ्ते में 4 बार या इससे अधिक",
            "2. How many drinks containing alcohol do you have on a typical day when you are drinking ?": "2. जब आप शराब पीते हैं तो एक सामान्य दिन में आप अल्कोहल युक्त कितने पेय पीते हैं?",
            "a) 1 or 2": "a) 1 या 2",
            "b) 3 or 4": "b) 3 या 4",
            "c) 5 or 6": "c) 5 या 6",
            "d) 7 or 9": "d) 7 या 9",
            "e) 10 or more": "e) 10 या इससे अधिक",
            "3. How often do you have six or more drinks on one occasion ?": "3. आप कितनी बार एक अवसर पर छः या उससे अधिक पेय पीते हैं?",
            "a) Never": "a) कभी नहीं",
            "b) Less than monthly": "b) मासिक से कम",
            "c) Monthly": "c) मासिक",
            "d) Weekly": "d) साप्ताहिक",
            "e) Daily or almost Daily": "e) रोजाना या लगभग रोजाना",
            "4. How often during the last year have you found that you were not able to stop drinking once you had started ?": "4. पिछले वर्ष के दौरान आपने कितनी बार पाया है कि एक बार शराब पीना शुरू करने के बाद आप इसे बंद नहीं कर पा रहे हैं?",
            "a) Never": "a) कभी नहीं",
            "b) Less than monthly": "b) मासिक से कम",
            "c) Monthly": "c) मासिक",
            "d) Weekly": "d) साप्ताहिक",
            "e) Daily or Almost Daily": "e) रोजाना या लगभग रोजाना",
            "5. How often during the last year you failed to do so what was normally expected of you because of drinking ?": "5. पिछले वर्ष के दौरान आप कितनी बार शराब पीने के कारण वह करने में असफल रहे जिसकी आपसे सामान्यतः अपेक्षा की जाती थी?", 
            "a) Never": "a) कभी नहीं",
            "b) Less than monthly": "b) मासिक से कम",
            "c) Monthly": "c) मासिक",
            "d) Weekly": "d) साप्ताहिक",
            "e) Daily or Almost Daily": "e) रोजाना या लगभग रोजाना",
            "6. How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session ?": "6. पिछले वर्ष के दौरान कितनी बार आपको भारी शराब पीने के बाद खुद को तरोताजा करने के लिए सुबह सबसे पहले पेय की आवश्यकता पड़ी है?",
            "a) Never": "a) कभी नहीं",
            "b) Less than monthly": "b) मासिक से कम",
            "c) Monthly": "c) मासिक",
            "d) Weekly": "d) साप्ताहिक",
            "e) Daily or Almost Daily": "e) रोजाना या लगभग रोजाना",
            "7. How often during the last year have you had a feeling of guilt or remorse after drinking ?": "7. पिछले वर्ष के दौरान आपको कितनी बार शराब पीने के बाद अपराधबोध या पश्चाताप की भावना महसूस हुई है?",
            "a) Never": "a) कभी नहीं",
            "b) Less than monthly": "b) मासिक से कम",
            "c) Monthly": "c) मासिक",
            "d) Weekly": "d) साप्ताहिक",
            "e) Daily or Almost Daily": "e) रोजाना या लगभग रोजाना",
            "8. How often during the last year have you been unable to remember what happened the night before because of your drinking ?": "8. पिछले वर्ष के दौरान आप कितनी बार यह याद रखने में असमर्थ रहे हैं कि शराब पीने के कारण पिछली रात क्या हुआ था?",
            "a) Never": "a) कभी नहीं",
            "b) Less than monthly": "b) मासिक से कम",
            "c) Monthly": "c) मासिक",
            "d) Weekly": "d) साप्ताहिक",
            "e) Daily or Almost Daily": "e) रोजाना या लगभग रोजाना",
            "9. Have you or someone else been injured because of your drinking ?": "9. क्या आप या कोई और आपके शराब पीने के कारण घायल हुआ है?",
            "a) No": "a) नहीं",
            "b) Yes, but not in the last year": "b) हां, लेकिन पिछले साल नहीं",
            "c) Yes, during the last year": "c) हां, पिछले साल के दौरान",
            "10. Has a relative, friend, doctor, or other healthcare worker been concerned about your drinking or suggested that you cut down?": "10. क्या किसी रिश्तेदार, मित्र, डॉक्टर, या अन्य स्वास्थ्य कार्यकर्ता को आपके शराब पीने के बारे में चिंता हुई है या आपको इसे कम करने का सुझाव दिया है? ",
            "a) No": "a) नहीं",
            "b) Yes, but not in the last year": "b) हां, लेकिन पिछले साल नहीं",
            "c) Yes, during the last year": "c) हां, पिछले साल के दौरान"
          }
          
          
          
        
      },
    },
  });

export default i18n;
