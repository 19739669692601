import React from 'react'
import { Container } from 'react-bootstrap'

function Note() {
  return (
    <div>
    <Container className=''>
        <div className='flex justify-center tek2'>
            <p className='text-lg text-[#9c2b78] tek'>Note: This is a screening test and does not provide any diagnosis</p>
         </div>
    </Container>
    </div>
  )
}

export default Note